import * as React from "react";
import Icon from "../assets/bitcoin.svg";
import "../styles/index.scss";

export default function IndexPage() {
  return (
    <main>
      <title>Indy Bitcoin</title>
      <div className="container">
        <Icon />
      </div>
      <div className="container">
        <h1>Welcome to Indy Bitcoin!</h1>
      </div>
      <div className="container">
        <h3>Home of the Indy bitcoin meetup.</h3>
      </div>
      <div className="container">
        <a href="/whitepaper">Bitcoin Whitepaper</a>
      </div>
    </main>
  );
}
